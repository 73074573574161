<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6">TÀI KHOẢN</span>
      </v-card-title>
      <v-card-text>
        <v-tabs
          fixed-tabs
          background-color="primary"
          dark
        >
          <v-tab
            class="secondary--text"
          >
            Danh sách
          </v-tab>
          <v-tab
            class="secondary--text"
          >
            Lịch sử
          </v-tab>
          <v-tab-item>
            <Datatables
              :headers="headers"
              :items="itemsWithIndex"
              :controller="controller"
              :group="false"
            >
              <template #[`item.Access`]="{ item }">
                <span
                  :class="`text-h6 ${AccountStatus[item.Access].c}--text`"
                >
                  {{AccountStatus[item.Access].t}}
                </span>
              </template>
              <template #[`item.Balance`]="{ item }">
                <span
                  class="text-h6 secondary--text"
                >
                  {{DoiTien(item.Balance)}}
                </span>
              </template>
              <template #[`item.Rate`]="{ item }">
                <span class="text-h6 blue--text">{{item.Rate}}%</span>
              </template>
              <template #[`item.KichHoat`]="{ item }">
                <v-switch
                  v-if="item.Access !== 'admin'"
                  :value="item.Access !== 'inactive'"
                  :input-value="item.Access !== 'inactive'"
                  :success="item.Access !== 'inactive'"
                  :error="item.Access === 'inactive'"
                  :label="item.Access === 'inactive' ? 'Chưa kích hoạt':'Đã kích hoạt'"
                  @change="doActive(item._id,item.Access)"
                />
              </template>
            </Datatables>
          </v-tab-item>
          <v-tab-item>
            <Datatables
              :headers="headersTrans"
              :items="(Filter.Transaction_Pending === false) ? TransactionsWithIndex:TransactionsPendingWithIndex"
              :loading="Loader.Transaction"
            >
              <template #tools>
                <v-switch
                  v-model="Filter.Transaction_Pending"
                  color="blue"
                  :label="(Filter.Transaction_Pending) ? 'Chờ duyệt':'Tất cả'"
                  dark
                />
              </template>
              <template #[`item.Status`]="{ item }">
                <template v-if="item.Status === 'cho_duyet'">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="TransactionStatus[item.Status].c"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        text
                      >
                        {{TransactionStatus[item.Status].t}}
                      </v-btn>
                    </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn
                              color="green"
                              @click="doApprove(item._id)"
                            >
                              Approve
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn
                              color="red darken-2"
                              @click="doRefund(item._id)"
                            >
                              Refund
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                  </v-menu>
                </template>
                <template v-else>
                  <v-btn
                    text
                    :color="TransactionStatus[item.Status].c"
                  >
                    {{TransactionStatus[item.Status].t}}
                  </v-btn>
                </template>
              </template>
              <template #[`item.SoTien`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                      :color="TransactionStatus[item.Status].c"
                    >
                      {{DoiTien(item.SoTien)}}
                    </v-btn>
                  </template>
                  <span>{{item.GhiChu}}</span>
                </v-tooltip>
              </template>
              <template #[`item.Start`]="{ item }">
                <v-btn
                  text
                  color="blue"
                >
                  {{DoiTien(item.Start)}}
                </v-btn>
              </template>
              <template #[`item.After`]="{ item }">
                <v-btn
                  text
                  :color="TransactionStatus[item.Status].c"
                >
                  {{DoiTien(item.After)}}
                </v-btn>
              </template>
              <template #[`item.DongBang`]="{ item }">
                {{doIsFreeze(item)}}
                <p v-if="doIsFreeze(item) !== 'Không'">
                  <v-btn
                    class="my-2"
                    color="secondary"
                    x-small
                    :disabled="loading"
                    @click="doRemoveFreeze(item._id)"
                  >
                    Gỡ băng
                  </v-btn>
                </p>
              </template>
            </Datatables>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="Box.Charge"
      max-width="900px"
      persistent
      ransition="dialog-bottom-transition"
    >
      <Header
        :title="(chargeData.freeze === undefined) ? 'Rút tiền':'Nạp tiền'"
        :close.sync="Box.Charge"
      >
          <v-card-title>{{(chargeData.freeze === undefined) ? 'Rút tiền':'Nạp tiền'}}</v-card-title>
          <v-card-text>
            <v-form
              v-model="Valid.Charge"
            >
            <v-row>
              <v-col
                cols="6"
              >
                <v-currency-field
                  v-model="chargeData.sotien"
                  :rules="[required('Số tiền')]"
                  label="Số tiền"
                />
              </v-col>
              <v-col
                cols="6"
                v-if="chargeData.freeze !== undefined"
              >
                <v-switch
                  v-model="chargeData.freeze"
                  :success="chargeData.freeze === true"
                  :error="chargeData.freeze === false"
                  :label="(chargeData.freeze) ? 'Đóng băng':'Không đóng băng'"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="chargeData.ghichu"
                  label="Ghi chú"
                />
              </v-col>
            </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="doCharge"
            >
              {{(chargeData.freeze === undefined) ? 'Rút':'Nạp'}}
            </v-btn>
          </v-card-actions>
      </Header>
      <loading :status="Loader.Charge" />
    </v-dialog>
    <v-dialog
      v-model="Box.Rate"
      max-width="600px"
      persistent
      ransition="dialog-bottom-transition"
    >
      <Header
        title="Chỉnh sửa Rate"
        :close.sync="Box.Rate"
      >
          <v-card-title>Rate</v-card-title>
          <v-card-text>
            <v-form
              v-model="Valid.Rate"
            >
            <v-row>
              <v-col
                cols="6"
              >
                <v-currency-field
                  v-model="rateData.rate"
                  :rules="[required('Rate')]"
                  label="Rate"
                  append-icon="mdi-percent"
                />
              </v-col>
            </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              @click="doRate"
            >
              Thay đổi
            </v-btn>
          </v-card-actions>
      </Header>
      <loading :status="Loader.Rate" />
    </v-dialog>
    <loading :status="loading" />
  </div>
</template>

<script>
import Header from '@/components/base/pagehead.vue'
import Datatables from '@/components/base/datatables.vue'
import loading from '@/components/base/loading.vue'
import Account from '@/modules/account.js';
import { Toast, DoiTien } from '@/helpers'
import { AccountStatus, TransactionStatus } from '@/helpers/status'
import moment from 'moment-timezone'
import validations from '@/helpers/validations'
import Transaction from  '@/modules/transaction.js';

export default {
  watch: {
  },
  components: {
    loading,
    Datatables,
    Header,
  },
  data () {
    return {
      ChargeValid: false,
      loading: false,
      items: [],
      transaction: [],
      chargeData: {},
      rateData:{},
      Filter:{
        Transaction_Pending: false,
      },
      headers: [
        { text: 'STT', align: 'center', sortable: true,value: 'index',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Tài khoản', align: 'center', sortable: false,value: 'email',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Số dư', align: 'center', sortable: true,value: 'Balance',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Rate', align: 'center', sortable: true,value: 'Rate',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Loại TK', align: 'center', sortable: true,value: 'Access',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Kích hoạt', align: 'center', value: 'KichHoat',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Thao tác', align: 'center', sortable: false,value: 'controller',width: "auto",class:"primary secondary--text text-center" },
      ],
      headersTrans: [
        { text: 'STT', align: 'center', sortable: true,value: 'index',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Tài khoản', align: 'center', sortable: false,value: 'Account.email',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Số tiền', align: 'center', sortable: true,value: 'SoTien',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Trước', align: 'center', sortable: true, value: 'Start',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Sau', align: 'center', sortable: true, value: 'After',width:"auto",class:"primary secondary--text text-center" },
        { text: 'Hành động', align: 'center', sortable: false,value: 'Status',width: "auto",class:"primary secondary--text text-center" },
        { text: 'Đóng băng', align: 'center', sortable: true, value: 'DongBang',width: "auto",class:"primary secondary--text text-center" },
      ],
      Box: {
        Charge:false,
        Rate: false,
      },
      Valid:{
        Charge: false,
        Rate: false,
      },
      Loader:{
        Charge: false,
        Rate: false,
        Transaction: false,
      },
      AccountStatus,
      TransactionStatus,
    }
  },
  computed:{
    templateCharge(){
      return {
        id:undefined,
        sotien:0,
        freeze: true,
        ghichu: '',
      }
    },
    TransactionsPendingWithIndex(){
      return this.TransactionsWithIndex.filter(item=>{
        return item.Status === 'cho_duyet'
      })
    },
    TransactionsWithIndex(){
      return this.transaction.map(
        (items, index) => ({
          ...items,
          index: index + 1,
          SoTien: (items.Status === 'rut') ? '-'+items.SoTien:items.SoTien,
        }))
    },
    itemsWithIndex(){
      return this.items.map(
        (items, index) => ({
          ...items,
          index: index + 1,
        }))
    },
    controller(){
      let controller = [
        {t:'Nạp',c:'green',a:this.doRecharge, i:'mdi mdi-credit-card-plus-outline'},
        {t:'Rút',c:'red lighten-2',a:this.doWithdraw, i:'mdi mdi-credit-card-minus-outline'},
        {t:'Rate',c:'blue lighten-2',a:this.doRateUpdate, i:'mdi-brightness-percent'},
      ]
      return controller
    },
  },
  methods:{
    ...validations,
    ...Account,
    DoiTien,
    doRemoveFreeze(id){
      this.loading = true;
      Transaction.Remove({id}).then(resp =>{
        if(resp.success){
          this.doGetTransaction();
        }
      }).finally(()=>{
        this.loading = false;
      });
    },
    doApprove(id){
      this.loading = true
      this.Approve(id).finally(this.Callback)
    },
    doRefund(id){
      this.loading = true
      this.Refund(id).finally(this.Callback)
    },
    doIsFreeze(item){
      const created = moment(item.createdAt).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:MM');
      const freeze = moment(item.DongBang).tz('Asia/Ho_Chi_Minh').format('DD/MM/YYYY HH:MM');
      if(created === freeze || moment(item.DongBang).tz('Asia/Ho_Chi_Minh').diff(moment().tz('Asia/Ho_Chi_Minh'),'seconds') < 0) return 'Không'
      return freeze
    },
    doRateUpdate(item){
      this.rateData = Object.assign({},{id:item._id,rate:item.Rate})
      this.Box.Rate = true
    },
    doRecharge(item){
      this.chargeData = Object.assign({},this.templateCharge)
      this.chargeData.id = item._id;
      this.Box.Charge = true
    },
    doWithdraw(item){
      this.chargeData = Object.assign({},this.templateCharge)
      this.chargeData.id = item._id;
      this.chargeData.freeze = undefined;
      this.Box.Charge = true
    },
    doCharge(){
      this.Loader.Charge = true;
      const {id,sotien,freeze, ghichu} = this.chargeData;
      if(this.chargeData.freeze !== undefined){
        this.Nap({id,sotien,freeze, ghichu}).then(this.Callback).finally(this.CallbackCharge)
      }else{
        this.Rut({id,sotien,freeze, ghichu}).then(this.Callback).finally(this.CallbackCharge)
      }
    },
    doRate(){
      this.Loader.Rate = true;
      const {id,rate} = this.rateData;
      Account.Rate({id,rate}).then(this.Callback).then(()=>{
        this.Box.Rate = false;
      }).finally(()=>{
        this.Loader.Rate = false;
      })
    },
    Callback(){
      this.doDownload()
      return true;
    },
    CallbackCharge(){
      this.Loader.Charge = false;
      this.Box.Charge = false;
    },
    doActive(id,type){
      this.loading = true
      this.Active(id,type).then(this.Callback).finally(()=>{
        this.loading
      })
    },
    doGetTransaction(){
      this.Loader.Transaction = true;
      return this.GetTransaction().then(json=>{
        console.log('Đã tải lịch sử thanh toán !');
        const {data} = json
        if(data){
          this.transaction = data
        }else{
          Toast.Error('Không thể tải dữ liệu !!!');
        }
      }).finally(()=>{
        this.Loader.Transaction = false;
      })
    },
    doGetAll(){
      return this.GetAll().then(json=>{
        console.log('Đã tải danh sách thành viên !');
        const {data} = json
        if(data){
          this.items = data
        }else{
          Toast.Error('Không thể tải dữ liệu !!!');
        }
      })
    },
    doDownload(){
      this.loading = true;
      Promise.all([this.doGetAll(), this.doGetTransaction()]).finally(() => {
        console.log('Đã hoàn thành !!!')
        this.loading = false;
      });
    }
  },
  mounted(){
    this.doDownload()
  }
}
</script>

<style>

</style>